<template>
  <div>
    <BaseTableWrapper
      v-if="!loading"
      :special-text-formatting="specialTextFormatting"
      :table-init="tableInit"
    >
      <h1 class="page-header">Customers</h1>
    </BaseTableWrapper>
    <ImportModal
      v-if="showImportDialog"
      :show="showImportDialog"
      entity-type="customer"
      @close="importCancelled"
      @complete="importComplete"
    >
      <template slot="header">
        <h2>Customer Import</h2>
      </template>
      <template slot="downloadTemplate">
        <div>
          <strong>Need help? Download our CSV template.</strong>
        </div>
        <v-btn
          class="download-template-button btn-secondaryaction"
          @click="downloadTemplate"
        >
          Download Template
        </v-btn>
        <br />
        <br />
      </template>
      <template slot="note">
        <strong>
          Note: Customer Accounts must already be added or imported for
          customers to link.
        </strong>
        <br />
        <br />
      </template>
    </ImportModal>
  </div>
</template>

<script>
import customers from '@/services/customers'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import ImportModal from '@/components/ImportModal.vue'
import { phoneFormatFilter } from '@/utils/phone'
import CustomersDetailList from '@/components/CustomersListDetail.vue'
import { authComputed } from '@/state/helpers'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import brokerActionsWrapper from '@/components/CustomerActionsBrokerWrapper.js'
import CustomerTableActions from '@/components/CustomerTableActions.vue'
import { saveAs } from 'file-saver'
import GenericActionableColumn from '@/components/GenericActionableColumn.vue'

export default {
  metaInfo() {
    return {
      title: 'Customers',
    }
  },
  components: {
    BaseTableWrapper,
    ImportModal,
  },
  data() {
    return {
      isAdmin: false,
      specialTextFormatting: {
        phone: phoneFormatFilter,
      },
      loading: true,
      showImportDialog: false,
      tableInit: {
        addNewEnabled: false,
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        enableColumnConfig: true,
        externalShareFilters: this.receiveFilters,
        externalShareSorts: this.receiveSorts,
        detail: CustomersDetailList,
        detailKeyId: 'customerId',
        importTable: this.importClicked.bind(this),
        addNewHandler: () => this.$router.push({ name: 'customers.add' }),
        tableId: 'customers_tv_view',
        enableAdditionalActions: true,
        enableExport: false,
        exportServiceAction: (params) => customers.exportCustomers(params),
        exportName: 'customers.csv',
        enableImport: false,
        forceRefresh: 0,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns: [],
        rowClass: this.rowClass.bind(this),
        collection: 'Customers',
        action: (params) => customers.getCustomers(params),
      },
      columns: [
        {
          _t_id: '7d828b93-488b-4c18-9b4f-20d3a4d309a1',
          prop: 'customerId',
          component: GenericActionableColumn,
          displayType: 'clickable-customer-id',
          text: 'ID',
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'startswith',
        },
        {
          _t_id: '74be229b',
          prop: 'firstName',
          text: 'First Name',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'contains',
          sortProp: 'firstName',
          defaultSort: true,
        },
        {
          _t_id: '69a412b7',
          prop: 'lastName',
          text: 'Last Name',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'contains',
          sortProp: 'lastName',
        },
        {
          _t_id: '59a1d601',
          prop: 'email',
          text: 'Email',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'contains',
          sortProp: 'email',
        },
        {
          _t_id: '59a1dd01',
          prop: 'phone',
          text: 'Phone',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'contains',
          sortProp: 'phone',
        },
        {
          _t_id: '59a1dd81',
          prop: 'customerAccountName',
          text: 'Customer Account',
          sort: true,
          filter: true,
          detail: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },

  mounted() {
    this.tableInit.addNewEnabled = this.tableInit.enableImport = this.hasPermission(
      'canAddCustomer'
    )
    this.tableInit.enableExport = this.canExport()
    this.columns.push(
      {
        _t_id: '59a18801',
        prop: 'bookings',
        text: 'Bookings Per Year',
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        filterType: 'contains',
        sortProp: 'bookings',
      },
      {
        _t_id: 'xb7bcc554',
        prop: 'isSignedUp',
        text: 'Marketplace',
        computedText: (item) => (item ? 'Yes' : 'No'),
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        sort: false,
        filter: true,
        detail: false,
        filterType: 'eq',
        defaultHidden: true,
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
      },
      {
        _t_id: 'f6f6b58cedc045b99771ba364ef74f91',
        prop: 'industryDescription',
        text: 'Industry Description',
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        filterType: 'contains',
        sortProp: 'industryDescription',
        defaultHidden: true,
      },
      {
        _t_id: '5de9a4e92ccb',
        prop: 'isBanned',
        text: 'Banned',
        computedText: (item) => (item ? 'Yes' : 'No'),
        sort: true,
        filter: true,
        filterType: 'eq',
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        defaultHidden: true,
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
      }
    )

    this.columns.push({
      _t_id: '52f5c51ef2f44e9aae89ceffb91139ff',
      prop: 'industries',
      text: 'Industries',
      computedText: (item, row) => (item ? item.replaceAll('||', ' / ') : ''),
      sort: true,
      sortProp: 'industries',
      filterProp: 'industries',
      filter: true,
      type: 'text',
      method: 'and',
      childMethod: 'and',
      filterType: 'contains',
      defaultHidden: true,
    })

    const brokerWrappedBaseActions = brokerActionsWrapper(CustomerTableActions)
    this.columns.unshift({
      _t_id: '4628e62f',
      prop: '/',
      text: 'Actions',
      component: brokerWrappedBaseActions,
      sort: false,
      filter: false,
      detail: false,
      type: 'actions',
    })

    this.tableInit.columns.push(...this.columns)
    this.loading = false
  },
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    importCancelled() {
      this.showImportDialog = false
    },
    importClicked() {
      this.showImportDialog = false
      this.$nextTick(() => {
        this.showImportDialog = true
      })
    },
    importComplete() {
      this.showImportDialog = false
      this.tableInit.forceRefresh = 1 + Math.random()
    },
    downloadTemplate() {
      let csv = `firstName,lastName,email,notes,phone,fax,customerAccountId
John,Doe,example@example.com,customer notes,7770001111,7770001112,100
`
      csv = new Blob(['\ufeff', csv])
      saveAs(csv, 'customersTemplate.csv')
    },
    canExport() {
      const roles = this.currentUserProfile?.roles || []
      const canExportRole = roles.find(
        (r) =>
          !!r.permissions.find(
            (p) => p.permissionName === 'canViewCompanyMetrics'
          )
      )
      return !!canExportRole && !this.isBroker
    },
    rowClass(rowProps) {
      const { item } = rowProps
      if (item?.isBanned) {
        return ' error-background'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.download-template-button {
  margin-left: 0px;
}
</style>
