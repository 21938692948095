import Vue from 'vue'
import { authComputed } from '@/state/helpers'
import {store} from "@/state/store";

const MenuDataMapper = function (row, rowProps, handleAction) {
  const menuOut = []
  menuOut.push(
    {
      icon: () => 'remove_red_eye',
      title: () => 'View Customer',
      color: () => 'primary',
      id: 'view',
      quickAccess: true,
      to: {
        name: `customers.details`,
        params: {
          id: row[`customerId`],
        },
      },
    },
    {
      icon: () => 'trash',
      id: 'delete',
      viewBox: '0 0 32 32',
      title: () => 'Delete Customer',
      condition: () =>
          store.getters['auth/hasPermission']('canDeleteCustomer'),
      quickAccess: true,
      click: (() => () => {
        handleAction('DELETE_CUSTOMER', rowProps)
      })(),
    }
  )

  if (row.isBanned) {
    menuOut.push({
      icon: () => 'lock',
      id: 'remove-ban',
      title: () => 'Remove Ban',
      condition: () =>
          store.getters['auth/hasPermission']('canUpdateCustomer'),
      color: () => 'error',
      viewBox: '0 0 24 24',
      quickAccess: true,
      click: (() => () => {
        handleAction('REMOVE_BAN_CUSTOMER', rowProps)
      })(),
    })
  } else {
    menuOut.push({
      icon: () => 'unlock',
      title: () => 'Ban Customer',
      condition: () =>
          store.getters['auth/hasPermission']('canUpdateCustomer'),
      color: () => 'primary',
      id: 'ban',
      quickAccess: true,
      viewBox: '0 0 24 24',
      click: (() => () => {
        handleAction('BAN_CUSTOMER', rowProps)
      })(),
    })
  }

  return menuOut
}

export default function (component) {
  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...authComputed,
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(this.row, this.rowProps, this.handleAction),
        },
      })
    },
  })
}
