<template>
  <v-dialog :value="show" width="600" persistent>
    <v-card class="cr-modal">
      <v-container>
        <v-card-title>
          <slot name="header">Modal Title</slot>
        </v-card-title>

        <v-card-text>
          <v-flex layout row>
            <v-flex xs12>
              <slot name="downloadTemplate" />
            </v-flex>
          </v-flex>
          <v-divider />
          <slot name="note" />
          <div :class="`dropzone ${uploadFailure ? 'upload-failure' : ''}`">
            <input
              :id="`${id}-import-modal-input-capture-file`"
              ref="inputfile"
              accept="multipart/form-data"
              class="upload-input"
              type="file"
              @input="captureFile"
            />
            <div>
              <CRIcon v-if="uploadSuccess" color="primary">done</CRIcon>
              <CRIcon v-if="uploadFailure" color="error">alert</CRIcon>
              <CRIcon v-if="!uploadSuccess && !uploadFailure" color="primary">
                attachment
              </CRIcon>
            </div>
            <div v-if="uploadSuccess">
              <strong>{{ fileName }}</strong>
            </div>
            <div v-if="uploadFailure">
              <strong class="error-message">{{ uploadError }}</strong>
              <v-btn
                v-if="showErrorButtons"
                :id="`${id}-import-modal-button-generate-error-report`"
                small
                outline
                color="error"
                class="download-error-report-btn"
                @click="generateErrorReport"
              >
                Download Error Report
              </v-btn>
              <v-btn
                v-if="showErrorButtons"
                :id="`${id}-import-modal-button-try-again`"
                small
                color="primary"
                class="download-error-report-btn"
                @click="() => resetDownload(true)"
              >
                Try Again
              </v-btn>
            </div>
            <div v-if="!uploadSuccess && !uploadFailure">
              Drag and drop your CSV file here or
              <strong>
                <a
                  :id="`${id}-import-modal-link-upload`"
                  @click="(e) => e.preventDefault()"
                >
                  Click To Upload
                </a>
              </strong>
            </div>
            <div>
              <a
                v-if="uploadSuccess"
                :id="`${id}-import-modal-link-remove-file`"
                @click="removeFile"
              >
                Remove
              </a>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <slot name="actions">
            <v-layout justify-end>
              <v-btn
                :id="`${id}-import-modal-button-close`"
                class="btn-secondaryaction"
                :loading="loading"
                @click="$emit('close')"
              >
                Close
              </v-btn>
              <v-btn
                :id="`${id}-import-modal-button-upload-file`"
                class="btn-primaryaction"
                :disabled="!fileName"
                :loading="loading"
                @click="uploadFile"
              >
                Upload
              </v-btn>
            </v-layout>
          </slot>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import admin from '@/services/admin'

export default {
  props: {
    entityType: { type: String, default: undefined },
    uploadHandler: { type: Function, default: () => true },
    show: Boolean,
    showTemplateDownload: Boolean,
    id: { type: String, default: null },
  },
  data() {
    return {
      fileName: undefined,
      uploadError: undefined,
      uploadSuccess: false,
      uploadFailure: false,
      uploadedFile: undefined,
      loading: false,
      showErrorButtons: false,
      importErrors: [],
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    captureFile(evt) {
      const file = evt.target.files[0]
      const fileType = file?.type
      const allowedMimeTypes = [
        'application/csv',
        'text/csv',
        'application/vnd.ms-excel',
      ]
      const fileExtIsValid = (file?.name || '').endsWith('.csv')

      if (!allowedMimeTypes.includes(fileType)) {
        if (!fileExtIsValid) {
          this.fileName = undefined
          this.uploadSuccess = false
          this.uploadFailure = true
          this.uploadError = 'Please select the correct CSV file type.'
          this.resetDownload()
          return
        }
      }
      this.uploadFailure = false
      this.uploadSuccess = true
      this.uploadError = undefined
      this.fileName = file.name

      const formData = new FormData()
      formData.append('file', file)
      this.uploadedFile = formData
    },
    resetDownload(immediate) {
      const reset = () => {
        this.$refs.inputfile.value = ''
        this.fileName = undefined
        this.uploadSuccess = false
        this.uploadFailure = false
        this.uploadError = undefined
        this.showErrorButtons = false
        this.loading = false
      }
      if (immediate) {
        return reset()
      }
      setTimeout(reset, 5000)
    },
    removeFile(evt) {
      evt.preventDefault()
      this.fileName = undefined
      this.uploadedFile = undefined
    },
    async uploadFile() {
      this.loading = true
      const params = {
        file: this.uploadedFile,
        entityType: this.entityType,
      }
      const uploadResponse = await admin.import(params).catch((e) => e.response)
      const errors = uploadResponse?.data?.importResult?.errors || []
      const filteredErrors = errors.filter(
        (e) => e.message !== 'Record already exists'
      )
      this.importErrors = filteredErrors

      if (uploadResponse.status !== 200) {
        if (!filteredErrors.length && errors.length) {
          this.$emit('complete')
          this.loading = false
          return
        }
        this.uploadFailure = true
        this.uploadSuccess = false
        this.uploadError = `There was an error uploading your ${this.entityType} information`
        this.fileName = undefined
        this.loading = false
        if (filteredErrors.length) {
          this.showErrorButtons = true
        } else {
          this.resetDownload()
        }
        return
      }
      this.$emit('complete')
      this.loading = false
    },
    generateErrorReport(evt) {
      evt.preventDefault()
      if (this.importErrors.length) {
        const header = 'Row Number,Error Message\n'
        const errorReport = this.importErrors
          .map((e) => `${e.rowNumber},${e.message}`)
          .join('\n')
        this.makeDownload(
          header + errorReport,
          `${this.entityType}_Error Report`
        )
        this.importErrors = []
      }
    },
    makeDownload(csv, fileName) {
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = fileName
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0px;
}

.dropzone {
  transition: background-color 0.1s ease-out 0.1s;
  background-color: $white;
  border: 1px solid $gray-medium-light;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  padding: 7%;

  * {
    flex-grow: 1;
    text-align: center;
  }

  .upload-input {
    position: absolute;
    z-index: 1000;
    bottom: 70px;
    left: 20px;
    width: 560px;
    height: 100px;
    cursor: pointer;
    opacity: 0;
    background-color: pink;
  }
}
.upload-failure {
  transition: background-color 0.1s ease-out 0.1s;
  background-color: rgba(225, 0, 85, 0.3);
}
.error-message {
  color: $red;
}
.action-buttons {
  margin-right: 10px;
}
.download-error-report-btn {
  z-index: 1001;
}
</style>
