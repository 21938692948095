import { render, staticRenderFns } from "./CustomerTableActions.vue?vue&type=template&id=fa24f06a&scoped=true"
import script from "./CustomerTableActions.vue?vue&type=script&lang=js"
export * from "./CustomerTableActions.vue?vue&type=script&lang=js"
import style0 from "./CustomerTableActions.vue?vue&type=style&index=0&id=fa24f06a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa24f06a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VLayout,VList,VListTile,VListTileTitle,VMenu,VTooltip})
