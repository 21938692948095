<template>
  <v-container>
    <template v-if="row.action === 'DELETE_CUSTOMER'">
      <BaseDeletion
        :close="cancel"
        :collection-name="'customer'"
        :row="row"
        :special-identifier="'customerId'"
        :action="action"
      />
    </template>
    <template v-if="row.action === 'BAN_CUSTOMER'">
      <div class="ban-customer-expansion">
        <div class="headline">Ban Customer?</div>
        <span>Are you sure you would like to ban this customer?</span>
        <div class="button-container">
          <v-spacer />
          <v-btn
            :id="`${id || ''}-customer-detail-ban-button-cancel`"
            class="btn-secondaryaction"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :id="`${id || ''}-customer-detail-ban-button-target`"
            class="delete-target"
            flat
            @click="banTarget"
          >
            Ban Customer
          </v-btn>
        </div>
      </div>
    </template>
    <template v-if="row.action === 'REMOVE_BAN_CUSTOMER'">
      <div class="ban-customer-expansion">
        <div class="headline">Remove Ban for Customer?</div>
        <span>
          Are you sure you would like to remove the ban for this customer?
        </span>
        <div class="button-container">
          <v-spacer />
          <v-btn
            :id="`${id || ''}-customer-detail-remove-ban-button-cancel`"
            class="btn-secondaryaction"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :id="`${id || ''}-customer-detail-remove-ban-button-target`"
            class="delete-target"
            flat
            @click="removeBanTarget"
          >
            Remove Ban
          </v-btn>
        </div>
      </div>
    </template>
  </v-container>
</template>

<script>
import { phoneFormatFilter } from '@/utils/phone'
import { authComputed } from '@/state/helpers'
import BaseDeletion from '@/components/BaseDeletion.vue'
import customers from '@/services/customers'

export default {
  components: {
    BaseDeletion,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      reservation: {},
      loading: false,
      phoneFormatFilter,
      action: (params) => customers.deleteCustomer(params),
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    this.$emit('row-expanded', this.props)
  },
  methods: {
    banTarget() {
      let params = {}
      params.id = this.row.customerId
      params.payload = {
        isBanned: true,
      }
      customers.modifyCustomer(params).then(() => {
        this.cancel()
        this.refreshQuery()
        this.$store.dispatch('app/showAlert', {
          message: `Customer successfully banned.`,
        })
      })
    },
    removeBanTarget() {
      let params = {}
      params.id = this.row.customerId
      params.payload = {
        isBanned: false,
      }
      customers.modifyCustomer(params).then(() => {
        this.cancel()
        this.refreshQuery()
        this.$store.dispatch('app/showAlert', {
          message: `Ban successfully removed for customer.`,
        })
      })
    },
    refreshQuery() {
      this.$emit('refresh-query-request')
    },
    cancel() {
      this.props.expanded = false
    },
  },
}
</script>

<style lang="scss">
.delete-target {
  color: $white !important;
  background-color: $red !important;
}

.v-btn.remove-right-margin {
  margin-right: 0 !important;
}

.detail-container {
  padding: 4px;
  background-color: $blue-pale;
}

.detail {
  padding: 10px;
}

.half-width {
  width: 50%;
}

.center-contents {
  text-align: center;
}

.contact-detail {
  margin-top: 0;
}

.unpad-buttons button:first-child {
  margin-left: 0;
}

.float-right {
  float: right;
}

.has-border {
  border-top: 1px solid gray;
  border-left: 1px solid gray;

  &.top-right {
    border-right: 1px solid gray;
    border-top-right-radius: 4px;
  }

  &.top-left {
    border-top-left-radius: 4px;
  }

  &.bottom-left {
    border-bottom: 1px solid gray;
    border-bottom-left-radius: 4px;
  }

  &.bottom-right {
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    border-bottom-right-radius: 4px;
  }
}
.ban-customer-expansion {
  margin-left: 32px;
  margin-top: 24px;
  margin-bottom: 20px;
}

.button-container {
  margin-left: -8px;
  margin-top: 8px;
}
</style>
