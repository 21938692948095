<template>
  <v-layout class="action-links" row>
    <div
      v-for="(item, idx) in quickAccessMenuItems"
      :id="`customer-table-actions-col-${rowProps.index}-action-link-${idx}`"
      :key="`customer-table-actions-col-${rowProps.index}-action-link-${idx}`"
      class="action-link-container"
    >
      <v-tooltip top>
        <template #activator="action">
          <span v-on="action.on">
            <CRIcon
              v-if="item.click"
              :id="`action-require-wrapper-${item.id}`"
              class="cursor-pointer"
              :class="item.icon()"
              :view-box="item.viewBox ? item.viewBox : '0 0 24 24'"
              :color="item.color ? item.color() : 'primary'"
              :file-name="snakeToPascal(item.icon())"
              @click.native="item.click"
            ></CRIcon>
            <router-link
              v-if="item.path"
              :id="`customers-list-${rowProps.index}-table-row-router-link-${idx}`"
              :to="{ path: item.path() }"
              class="action-link"
              :target="item.target ? item.target : '_self'"
            >
              <v-icon
                :id="`action-require-wrapper-${item.id}`"
                :class="item.icon()"
                :color="item.color ? item.color() : 'primary'"
              >
                {{ item.icon() }}
              </v-icon>
            </router-link>
            <a
              v-if="item.href"
              :id="`action-require-wrapper-${item.id}`"
              :href="item.href()"
              class="action-link"
              :target="item.target ? item.target : '_self'"
            >
              <v-icon
                :class="item.icon()"
                :color="item.color ? item.color() : 'primary'"
              >
                {{ item.icon() }}
              </v-icon>
            </a>
            <router-link
              v-if="item.to"
              :id="`action-require-wrapper-${item.id}`"
              target="_blank"
              :to="item.to"
              class="action-link"
            >
              <CRIcon
                v-if="item.icon"
                :color="(item.color && item.color()) || 'primary'"
                :view-box="item.viewBox ? item.viewBox : '0 0 24 24'"
              >
                {{ item.icon() }}
              </CRIcon>
            </router-link>
          </span>
        </template>
        <span>{{ item.title() }}</span>
      </v-tooltip>
    </div>
    <div>
      <v-menu bottom transition="slide-x-transition">
        <template #activator="more">
          <v-icon
            :id="`customers-list-${rowProps.index}-table-row-kebab-menu`"
            color="primary"
            v-on="more.on"
          >
            more_vert
          </v-icon>
        </template>
        <v-list>
          <template
            v-for="(item, i) in menuItems"
          >
            <v-list-tile
              :id="`action-require-wrapper-${item.id}`"
              :key="i"
              @click="handleClick(item)"
            >
              <v-list-tile-title>
                <div class="action-link">
                  <CRIcon
                    v-if="item.icon"
                    :color="item.color ? item.color() : 'primary'"
                    :view-box="item.viewBox ? item.viewBox : '0 0 24 24'"
                    :file-name="snakeToPascal(item.icon())"
                  ></CRIcon>
                  <span class="action-text">{{ item.title() }}</span>
                </div>
              </v-list-tile-title>
            </v-list-tile>
          </template>
        </v-list>
      </v-menu>
    </div>
  </v-layout>
</template>

<script>
import { snakeToPascal } from '@/utils/string'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    quickAccessMenuItems() {
      return this.menuItems.filter((m) => m.quickAccess)
    },
    menuItems() {
      return this.menu.filter((m) => m.condition ? m.condition() : true)
    }
  },
  methods: {
    snakeToPascal,
    handleClick(item) {
      if (item.path) {
        this.$router.push({ path: item.path() })
      } else if (item.href) {
        window.open(item.href(), '_self')
      } else if (item.click) {
        item.click()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.action-link,
.action-links a {
  display: flex;
  align-items: center;

  i.icon-credit-card {
    padding-right: 2px;
  }
}

.action-text {
  padding-left: 8px;
  font-size: 12px;
  text-decoration: none;
  color: $primary;
}

.action-link-container {
  margin-right: 2px;

  &:last-child {
    margin-right: 0;
  }
}
</style>
